.ra-typography {
  $root: &;
  font-family: var(--ra-font-family-body);
  margin: var(var(--ra-margin-y) var(--ra-margin-x), --ra-margin);

  &--size-xs {
    font-size: var(--ra-font-size-body-xs);
  }

  &--size-s {
    font-size: var(--ra-font-size-body-s);
  }

  &--size-m {
    font-size: var(--ra-font-size-body-m);
  }

  &--size-l {
    font-size: var(--ra-font-size-body-l);
  }

  &--size-xl {
    font-size: var(--ra-font-size-body-xl);
  }

  &--custom-size {
    font-size: var(--ra-typography-custom-size);
  }

  &--weight-regular {
    font-weight: var(--ra-font-weight-regular);
  }

  &--weight-semibold {
    font-weight: var(--ra-font-weight-semibold);
  }

  &--weight-bold {
    font-weight: var(--ra-font-weight-bold);
  }

  &--shadow {
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.26);
  }

  &--heading {
    font-family: var(--ra-font-family-heading);
  }
}
